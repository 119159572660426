import { useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useLocalStorage<T>(key: string, parse: (value: any) => T) {
  const [value, setValue] = useState<T>(() => {
    if (typeof window !== 'undefined') {
      const item = window.localStorage.getItem(`cf.${key}`);
      return parse(item && JSON.parse(item));
    }

    return null as never;
  });

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const save = (newValue: T) => {
    setValue(newValue);
    window.localStorage.setItem(`cf.${key}`, JSON.stringify(newValue));
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  return isClient ? ([value, save] as const) : ([null, () => {}] as const);
}
