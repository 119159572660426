import { useRef } from 'react';
import classNames from 'classnames';
import { toast } from 'sonner';
import { Button } from '@/shared/components';
import { useWindowEventListener } from '@/shared/hooks';
import { defaultToastClassnames } from '@/shared/hooks/useToast';
import { CheckCircleIcon, CloseIcon } from '@/shared/icons/small';
import { BOOST_TOASTER_ID } from './BoostToggle';
import BoostGlow from '../../../assets/svg/boost-glow.svg';
import useLocalStorage from '../../../hooks/useLocalStorage';
import useSettingsStore from '../../../hooks/useSettingsStore';

const BoostInfoBanner = () => {
  const enableBoost = useSettingsStore((state) => state.setBoostEnabled);
  const [showBanner, setShowBanner] = useLocalStorage('boost-banner', (value) =>
    typeof value === 'boolean' ? value : true,
  );
  const buttonRef = useRef<HTMLButtonElement>(null);

  const circleContainsPoint = (box: DOMRect | undefined, x: number, y: number) => {
    if (!box) return false;

    const circleX = box.x + box.width / 2;
    const circleY = box.y + box.height / 2;
    const radius = box.width / 2;

    return Math.sqrt((circleX - x) ** 2 + (circleY - y) ** 2) <= radius;
  };

  useWindowEventListener('click', (e) => {
    if (circleContainsPoint(buttonRef.current?.getBoundingClientRect(), e.clientX, e.clientY)) {
      setShowBanner(false);
    }
  });

  return (
    <div
      suppressHydrationWarning
      className={classNames(
        'absolute top-[-154px] hidden w-full items-center justify-between rounded-md border border-cf-gray-4 bg-cf-gray-3 p-4',
        showBanner && 'md:[@media(min-height:960px)]:flex',
      )}
    >
      <button
        ref={buttonRef}
        type="button"
        className="absolute -right-2 -top-2 z-10 flex h-5 w-5 items-center justify-center rounded-full border border-cf-gray-4 bg-cf-gray-3 text-cf-light-3"
        onClick={() => setShowBanner(false)}
      >
        <CloseIcon />
      </button>
      <div className="h-full w-full">
        <div className="flex flex-col space-y-3">
          <div className="flex flex-col gap-y-1">
            <span className="font-aeonikBold text-14 text-cf-white">Introducing Boost</span>
            <span className="max-w-[287px] text-14 text-cf-light-2">
              Boost speeds up swap times by reducing block confirmation times!
            </span>
          </div>
          <div className="flex items-center gap-x-2">
            <Button
              type="secondary-standard"
              size="small"
              onClick={() => {
                enableBoost(true);
                toast('Boost is enabled for Bitcoin', {
                  id: BOOST_TOASTER_ID,
                  className: `text-12 ${defaultToastClassnames}`,
                  icon: <CheckCircleIcon className="text-cf-green-1" />,
                  description: (
                    <span className="text-12 font-normal text-cf-light-2">
                      Bitcoin deposits are credited after 1 block confirmation
                    </span>
                  ),
                });
                setShowBanner(false);
              }}
            >
              Enable for Bitcoin
            </Button>
            <Button
              type="link-secondary"
              size="small"
              onClick={() => {
                window.open('https://docs.chainflip.io/swapping/intro-to-boost', '_blank');
              }}
            >
              Learn more
            </Button>
          </div>
        </div>
        <BoostGlow className="absolute right-0 top-0" />
      </div>
    </div>
  );
};

export default BoostInfoBanner;
