import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useMobile } from '@/shared/hooks';
import RouteList from '../components/SwapPage/RouteList/RouteList';
import SwapCard from '../components/SwapPage/SwapCard';
import { RoutesProvider } from '../hooks/useRoutes';
import useSwapRequestStore, { selectShowRouteList } from '../hooks/useSwapRequestStore';

const mobileAnimateDetails = {
  enter: {
    opacity: 1,
    scale: 1,
    display: 'block',
  },
  exit: {
    opacity: [1, 0.8, 0.3, 0],
    scale: 0.95,
    display: 'none',
  },
};

const desktopAnimateDetails = {
  enter: {
    opacity: 1,
    x: '0%',
    scale: 1,
  },
  exit: {
    opacity: [1, 0.8, 0.3, 0],
    x: '-5%',
    scale: 0.9,
  },
};

export default function Index(): JSX.Element {
  const isMobile = useMobile();
  const showRouteList = useSwapRequestStore(selectShowRouteList);

  return (
    <RoutesProvider>
      {isMobile ? (
        <div className="flex flex-col space-y-6" key="mobile">
          <SwapCard />
          <motion.div
            initial={false}
            animate={showRouteList ? 'enter' : 'exit'}
            variants={mobileAnimateDetails}
            transition={{
              type: 'spring',
              damping: 15,
              stiffness: 90,
              restDelta: 0.01,
              mass: 1,
            }}
          >
            <RouteList />
          </motion.div>
        </div>
      ) : (
        <div className="flex items-center justify-center space-x-6" key="desktop">
          <motion.div
            initial={false}
            animate={
              showRouteList ? { x: '0%' } : { x: '47%', transition: { delay: 0.1, duration: 0.3 } }
            }
          >
            <SwapCard />
          </motion.div>
          <motion.div
            className={classNames(showRouteList ? 'pointer-events-auto' : 'pointer-events-none')}
            initial={false}
            animate={showRouteList ? 'enter' : 'exit'}
            variants={desktopAnimateDetails}
            transition={{
              type: 'spring',
              damping: 15,
              stiffness: 90,
              restDelta: 0.01,
              mass: 1,
            }}
          >
            <RouteList />
          </motion.div>
        </div>
      )}
    </RoutesProvider>
  );
}
